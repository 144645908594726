import { APINewField } from "@/server/services/tables/types/fields";
import { BlockType } from "@prisma/client";
import { APIFieldLatest } from "server/services/tables/types/tables-latest";

export const blockType = "kanban-block";
export const blockTypeDbName = BlockType.Kanban;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;

export const ALLOWED_KANBAN_SORT_BY_FIELD_TYPES: APIFieldLatest["type"][] = [
  "text",
  "number",
  "datetime",
  "decimal",
  "string",
  "email",
];

export const DEFAULT_KANBAN_FIELDS: APINewField[] = [
  {
    name: "Status",
    type: "labeled_string",
    options: {
      choice_provider: {
        kind: "static",
        choices: [
          { label: "To Do", value: "To Do", color: "#e8e7e4" },
          { label: "In Progress", value: "In Progress", color: "#d2d8f3" },
          { label: "Done", value: "Done", color: "#b8e6bf" },
        ],
      },
    },
  },
  {
    name: "Title",
    type: "string",
  },
];
